import React from "react"
import Layout from "../components/UI/layout"
import Dots from "react-activity/dist/Dots"

import {
  GlobeAltIcon,
  ScaleIcon,
  LightningBoltIcon,
  AnnotationIcon,
  MailIcon,
} from "@heroicons/react/outline"
import { ExternalLinkIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import { usePortfolio } from "../context/PortfolioContext"

const transferFeatures = [
  {
    id: 1,
    name: "Competitive exchange rates",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: "No hidden fees",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: ScaleIcon,
  },
  {
    id: 3,
    name: "Transfers are instant",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: LightningBoltIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: "Mobile notifications",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: "Reminder emails",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
    icon: MailIcon,
  },
]
const blogPosts = [
  {
    id: 1,
    title: "Boost your conversion rate",
    href: "#",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    category: { name: "Article", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    preview:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.",
    author: {
      name: "Roel Aufderehar",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      href: "#",
    },
    readingLength: "6 min",
  },
  {
    id: 2,
    title: "How to use search engine optimization to drive sales",
    href: "#",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
    category: { name: "Video", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    preview:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
    author: {
      name: "Brenna Goyette",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      href: "#",
    },
    readingLength: "4 min",
  },
  {
    id: 3,
    title: "Improve your customer experience",
    href: "#",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    category: { name: "Case Study", href: "#" },
    imageUrl:
      "https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    preview:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.",
    author: {
      name: "Daniela Metz",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      href: "#",
    },
    readingLength: "11 min",
  },
]

const IndexPage = () => {
  const pf = usePortfolio()
  return (
    <Layout>
      <main>
        <div className="pt-10 bg-gray-900 sm:pt-12 lg:pt-8 lg:pb-12 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-12">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-28">
                  <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">Crypto investing</span>
                    <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-teal-300 to-cyan-500 sm:pb-5">
                      made simple
                    </span>
                  </h1>
                  <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                    The Simple Crypto Fund aims to outperform the broad crypto
                    markets via simple but professional portfolio management.
                  </p>
                  <div className="mt-10 sm:mt-12 flex justify-center lg:justify-start">
                    <div className="mt-3 sm:mt-0">
                      <Link
                        to="/fund/performance"
                        className="block w-48 py-3 px-4 rounded-md shadow text-center bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                      >
                        View portfolio
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-12 lg:relative lg:pt-12">
                <div className="mx-auto max-w-md px-4 flex justify-center items-center sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-4">
                  <h1 className="mt-4 text-4xl tracking-tight font-semibold text-white text-center sm:mt-5 sm:text-3xl lg:mt-6 xl:text-4xl">
                    <span className="lg:-mb-4 block bg-clip-text font-extrabold text-6xl text-transparent bg-gradient-to-r from-teal-300 to-cyan-500 sm:pb-5">
                      {pf.performance ? (
                        `${(pf.performance.eoly * 100).toFixed(0)} %`
                      ) : (
                        <p>Test</p>
                      )}
                    </span>
                    <span className="block">2022 return</span>
                  </h1>
                </div>
                <div className="mx-auto max-w-md px-4 flex justify-center items-center sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-4">
                  <h1 className="mt-4 text-4xl tracking-tight font-semibold text-white text-center sm:mt-5 sm:text-3xl lg:mt-6 xl:text-4xl">
                    <span className="lg:-mb-4 block bg-clip-text font-extrabold text-6xl text-transparent bg-gradient-to-r from-teal-300 to-cyan-500 sm:pb-5">
                      {pf.performance ? (
                        `${(pf.performance.si * 100).toFixed(0)} %`
                      ) : (
                        <Dots />
                      )}
                    </span>
                    <span className="block">since launch</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          // Features section}
          <div className="py-16 bg-gray-800 overflow-hidden lg:py-24">
            <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
              <div className="relative">
                <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
                  A better way to invest in crypto
                </h2>
              </div>

              <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="relative">
                  <h3 className="text-2xl font-extrabold text-white tracking-tight sm:text-3xl">
                    Leading-edge investment process
                  </h3>
                  <p className="mt-3 text-lg text-gray-200">
                    Our investment team has significant experience in managing
                    institutional portfolios. We believe that digital assets
                    represent one of the most disruptive investments of our
                    time. Accessing this opportunity using sound investment
                    principles can lead to significant returns.
                  </p>

                  <dl className="mt-10 space-y-10">
                    {transferFeatures.map(item => (
                      <div key={item.id} className="relative">
                        <dt>
                          <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                          </div>
                          <p className="ml-16 text-lg leading-6 font-medium text-white">
                            {item.name}
                          </p>
                        </dt>
                        <dd className="mt-2 ml-16 text-base text-gray-200">
                          {item.description}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>

                <div
                  className="mt-10 -mx-4 relative lg:mt-0"
                  aria-hidden="true"
                >
                  <img
                    className="relative mx-auto"
                    width={490}
                    src="https://tailwindui.com/img/features/feature-example-1.png"
                    alt=""
                  />
                </div>
              </div>

              <div className="relative mt-12 sm:mt-16 lg:mt-24">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2">
                    <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                      Always in the loop
                    </h3>
                    <p className="mt-3 text-lg text-gray-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Impedit ex obcaecati natus eligendi delectus, cum deleniti
                      sunt in labore nihil quod quibusdam expedita nemo.
                    </p>

                    <dl className="mt-10 space-y-10">
                      {communicationFeatures.map(item => (
                        <div key={item.id} className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                              <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                              {item.name}
                            </p>
                          </dt>
                          <dd className="mt-2 ml-16 text-base text-gray-500">
                            {item.description}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <img
                      className="relative mx-auto"
                      width={490}
                      src="https://tailwindui.com/img/features/feature-example-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {/* Blog section */}
        <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
          <div className="relative">
            <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
                Learn
              </h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Helpful Resources
              </p>
              <p className="mt-5 mx-auto max-w-prose text-xl text-gray-500">
                Phasellus lorem quam molestie id quisque diam aenean nulla in.
                Accumsan in quis quis nunc, ullamcorper malesuada. Eleifend
                condimentum id viverra nulla.
              </p>
            </div>
            <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              {blogPosts.map(post => (
                <div
                  key={post.id}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="flex-shrink-0">
                    <img
                      className="h-48 w-full object-cover"
                      src={post.imageUrl}
                      alt=""
                    />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <p className="text-sm font-medium text-cyan-600">
                        <a
                          href={post.category.href}
                          className="hover:underline"
                        >
                          {post.category.name}
                        </a>
                      </p>
                      <a href={post.href} className="block mt-2">
                        <p className="text-xl font-semibold text-gray-900">
                          {post.title}
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                          {post.preview}
                        </p>
                      </a>
                    </div>
                    <div className="mt-6 flex items-center">
                      <div className="flex-shrink-0">
                        <a href={post.author.href}>
                          <img
                            className="h-10 w-10 rounded-full"
                            src={post.author.imageUrl}
                            alt={post.author.name}
                          />
                        </a>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-900">
                          <a
                            href={post.author.href}
                            className="hover:underline"
                          >
                            {post.author.name}
                          </a>
                        </p>
                        <div className="flex space-x-1 text-sm text-gray-500">
                          <time dateTime={post.datetime}>{post.date}</time>
                          <span aria-hidden="true">&middot;</span>
                          <span>{post.readingLength} read</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="relative bg-gray-900">
          <div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
            <img
              className="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
              alt=""
            />
            <div
              aria-hidden="true"
              className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"
            />
          </div>
          <div className="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
              <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
                Award winning support
              </h2>
              <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">
                We’re here to help
              </p>
              <p className="mt-3 text-lg text-gray-300">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et,
                egestas tempus tellus etiam sed. Quam a scelerisque amet
                ullamcorper eu enim et fermentum, augue. Aliquet amet volutpat
                quisque ut interdum tincidunt duis.
              </p>
              <div className="mt-8">
                <div className="inline-flex rounded-md shadow">
                  <a
                    href="#"
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                  >
                    Visit the help center
                    <ExternalLinkIcon
                      className="-mr-1 ml-3 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
